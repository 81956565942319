/* popup toc */
#poptoc {
    border: thin outset;
    padding: 0.5em;
    border-top: #888899 1px solid;
    border-bottom: #ccc 1px solid;
    border-left: #888899 1px solid;
    border-right: #ccc 1px solid;
    background: #f5f5f5;
    min-width: 18em;
    max-width: 25em;
    width: 22em;
    margin-right: -10em;
    z-index: 1;
    position: absolute;
    display: none;
}

#poptoc a:hover {
    background: #ebebeb;
    text-decoration: none;
}

#poptoc a {
    color: black;
    border-style: none;
}

#poptoc h2 {
    color: navy;
    background: #ebebeb;
    font-size: small;
    font-weight: bold;
    padding: 0.3em;
    margin: 0;
    text-align: center;
    border: White solid 1px;
}

#poptoc h2 a {
    color: navy;
    text-decoration: none;
}

#poptoc h2 img {
    margin-bottom: -3px;
    margin-right: 2px;
}

#poptoc .nav {
    border-top: 1px gray solid;
    padding-top: 0.2em;
    text-align: center;
}

#poptoc a.here {
    color: #fff;
    background: #333;
    text-decoration: none;
    border: 1px solid white;
}
