
#body .htmltagcloud {
    font-size: 12px;
    line-height: 340%;
}

.menubar .htmltagcloud {
    font-size: 6px;
    line-height: 340%;
}

.menubar .htmltagcloud span {
    display: block;
}

.tagcloud0 {
    font-size: 100%;
}

.tagcloud1 {
    font-size: 110%;
}

.tagcloud2 {
    font-size: 120%;
}

.tagcloud3 {
    font-size: 130%;
}

.tagcloud4 {
    font-size: 140%;
}

.tagcloud5 {
    font-size: 150%;
}

.tagcloud6 {
    font-size: 160%;
}

.tagcloud7 {
    font-size: 170%;
}

.tagcloud8 {
    font-size: 180%;
}

.tagcloud9 {
    font-size: 190%;
}

.tagcloud10 {
    font-size: 200%;
}

.tagcloud11 {
    font-size: 210%;
}

.tagcloud12 {
    font-size: 220%;
}

.tagcloud13 {
    font-size: 230%;
}

.tagcloud14 {
    font-size: 240%;
}

.tagcloud15 {
    font-size: 250%;
}

.tagcloud16 {
    font-size: 260%;
}

.tagcloud17 {
    font-size: 270%;
}

.tagcloud18 {
    font-size: 280%;
}

.tagcloud19 {
    font-size: 290%;
}

.tagcloud20 {
    font-size: 300%;
}

.tagcloud21 {
    font-size: 310%;
}

.tagcloud22 {
    font-size: 320%;
}

.tagcloud23 {
    font-size: 330%;
}

.tagcloud24 {
    font-size: 340%;
}
