.tablescroll {
    max-height: 500px;
    overflow-x: auto;
    overflow-y: auto;

    & .h-scrollable,
    & .nobr {
        overflow-x: unset !important;
    }

    & table {
        border-collapse: separate;
        border-spacing: 0;

        & tr:not(:first-child) > *,
        & tbody tr:first-child > * {
            border-top: none;
        }
        & tr > *:not(:first-child) {
            border-left: none;
        }
    }

    & table thead:first-child {
        position: sticky;
        top: 0;
        z-index: 2;
    }
}

.tablescroll.tablescroll-fix-col {
    & table tr > *:first-child {
        position: sticky;
        left: 0;
        z-index: 1;
    }
}

.tablescroll.tablescroll-screen-sticky {
    max-height: unset;
    overflow: unset;

    & .h-scrollable,
    & .nobr {
        overflow-x: clip !important;
    }
}
