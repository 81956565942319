.twitter-timeline-alt {
    margin: 8px 0;
    padding: 6px 20px;
    border: 1px solid #ddd;
    border-radius: 0.8em;

    display: flex;
    flex-flow: row nowrap;

    & .twitter-timeline-alt-icon {
        font-size: 2.6em;
        margin: 4px 12px 0 0;
        line-height: 100%;
    }

    & .twitter-timeline-alt-desc {
    }
}
