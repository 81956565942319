.search-words .search-words-words {
    display: inline-block;
    margin: 0 -0.3em 0 0;
    padding: 0;
}
.search-words .search-words-words li {
    display: inline-block;
    margin: 0 0.3em 0 0;
    padding: 0;
}

mark {
    font-weight: bold;
}

mark.word0 {
    background-color: #ff6;
    color: black;
}

mark.word1 {
    background-color: #a0ffff;
    color: black;
}

mark.word2 {
    background-color: #9f9;
    color: black;
}

mark.word3 {
    background-color: #f99;
    color: black;
}

mark.word4 {
    background-color: #f6f;
    color: black;
}

mark.word5 {
    background-color: #800;
    color: white;
}

mark.word6 {
    background-color: #0a0;
    color: white;
}

mark.word7 {
    background-color: #886800;
    color: white;
}

mark.word8 {
    background-color: #004699;
    color: white;
}

mark.word9 {
    background-color: #909;
    color: white;
}
