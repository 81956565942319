// 全体のオンオフ
#responsive-navigation {

    & #responsive-navigation-drawer {
        display: none;

        @media (--layout-sm) {
            display: block;
        }
    }

    @media (--layout-sm) and (orientation: landscape) {
        .float-buttons-base {
            bottom: 50px;
        }
    }
}

// HTML で作る UI のスタイル
#edit-menu-items {
    & ul {
        margin: 0 0 10px;
        padding: 0;

        & li {
            list-style: none;

            & a {
                display: block;
                margin: 0;
                padding: 5px;
                border: none;
                border-bottom: 1px solid #eee;
                background-color: white;
                color: black;

                & img {
                    vertical-align: middle;
                }
            }
        }
    }
}

// それ以外は Svelte コンポーネント内でスタイルを定義
