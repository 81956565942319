/* amazon.inc.php */
div.amazon_img {
    margin: 16px 10px 8px 8px;
    text-align: center;
}

div.amazon_imgetc {
    margin: 0 8px 8px 8px;
    text-align: center;
}

div.amazon_sub {
    font-size: 90%;
}

div.amazon_avail {
    margin-left: 150px;
    font-size: 90%;
}

td.amazon_td {
    font-size: 90%;
    text-align: center;
}

table.amazon_tbl {
    border: 0;
    width: 115px;
    font-size: 90%;
    text-align: center;
}
