.system-icon {
    display: inline-block;
    background-image: url('icons.png');

    &.toolbar {
        width: 20px;
        height: 20px;
        margin-bottom: -3px;

        &.reload   { background-position:      0 0; }
        &.new      { background-position:  -20px 0; }
        &.edit     { background-position:  -40px 0; }
        &.freeze   { background-position:  -60px 0; }
        &.unfreeze { background-position:  -80px 0; }
        &.diff     { background-position: -100px 0; }
        &.upload   { background-position: -120px 0; }
        &.copy     { background-position: -140px 0; }
        &.rename   { background-position: -160px 0; }
        &.top      { background-position: -180px 0; }
        &.list     { background-position: -200px 0; }
        &.search   { background-position: -220px 0; }
        &.recent   { background-position: -240px 0; }
        &.backup   { background-position: -260px 0; }
        &.refer    { background-position: -280px 0; }
        &.help     { background-position: -300px 0; }
    }
    &.file {
        width: 20px;
        height: 20px;
        background-position: -120px 0;
        margin-bottom: -3px;
    }
    &.ext {
        width: 12px;
        height: 18px;
        background-position: 0 -20px;
        margin-bottom: -3px;
    }

    &.paraedit {
        width: 9px;
        height: 9px;
        background-position: -20px -20px;
    }
    &.lock {
        width: 9px;
        height: 9px;
        background-position: -20px -30px;
    }
    &.rss {
        width: 36px;
        height: 14px;
        background-position: -40px -20px;
    }
}
