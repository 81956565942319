.splitinclude::after {
    content: "";
    display: block;
    clear: both;
}

.splitinclude-included-page-container {
    vertical-align:top;
    float:left;
}

.splitinclude-included-page {
    margin:0 2px;
}
