/**
 * PukiWiki Plugin Code highlight
 *
 */
#contents :not(pre) > code[class*="language-"],
#contents pre[class*="language-"] {
    background-color: #F0F8FF;
    border: 1px solid #DDDDEE;
    border-right-color: #888899;
    border-bottom-color: #888899;
    margin: 1em 2em 0.5em 1em;
    line-height: 1.6em;
}
