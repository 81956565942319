.auto-downscale-help {
    margin: 1em;
    padding: .5em;

    & .auto-downscale-icon {
        margin-right: 4px;
    }
}

.auto-downscale-help.alert {
    border: 1px solid red;
    border-radius: .25em;
    background-color: pink;
    color: black;
}
.auto-downscale-help.loading,
.auto-downscale-help.warning {
    border: 1px solid yellow;
    border-radius: .25em;
    background-color: lightyellow;
    color: black;
}
