/* タイトル直下 */
ul.diff-log-specified-page-operation-menu {
    list-style: disc;
}
div.diff-log-info:not(:last-child) {
    margin-bottom: 2rem;
}

/* ナビゲーション */
div.diff-log-nav {
    margin-top: 20px;
    margin-bottom: 20px;
}
ul.diff-log-nav {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0;
    width: 100vw;
    font-size: 12px;
    border-bottom: 1px solid silver;
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    @media (--layout-lg) {
        display: block;
        width: auto;
    }

    & > li {
        display: inline-block;
        margin-right: 6px;
        padding: 0;
        list-style: none;
        text-align: center;

        @media (--layout-lg) {
            width: auto;
            margin-bottom: -1px;
        }

        & a.disabled {
            color: gray;
        }

        & .diff-log-nav-arrow {
            @media (--layout-lg) {
                padding: 6px 12px 0 12px;
            }
        }

        & .diff-log-nav-arrow-disabled {
            color: #888;
        }
    }

    & li {
        &.diff-log-nav-to-today, &.diff-log-nav-to-next-day {
            flex: 1 1 20%;
        }

        &.diff-log-nav-to-last-day {
            flex: 1 2 30%;
        }

        &.diff-log-nav-days {
            flex: 2 2 30%;

            & ul li .pager-month-char, & ul li .pager-date-char {
                font-size: 10px;
                margin: 0 .1em;
            }
        }
    }

    /* ナビゲーション内の日付と「次の週」「前の週」 */
    & li.diff-log-nav-days ul {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 0;
        padding-inline-start: 0;
        display: flex;
        flex-direction: column;
        height: 2rem;

        @media (--layout-lg) {
            display: inline-block;
            height: auto;
        }

        & li {
            margin: 0;
            padding: 8px;
            list-style: none;
            text-align: center;

            &.current-day {
                padding-bottom: 0;
            }

            &:not(.current-day) {
                display: none;
                z-index: 999;
                background-color: #FFF;
                border-top: solid 1px silver;
                border-left: solid 1px silver;
                border-right: solid 1px silver;

                @media (--layout-lg) {
                    display: inline-block;
                    background-color: inherit;
                    padding: 6px 12px;
                    margin-right: 6px;
                    z-index: 1;
                    border: none;
                }
            }

            &:not(.current-day):nth-last-child(2) {
                border-bottom: solid 1px silver;

                @media (--layout-lg) {
                    border: none;
                }
            }

            &.diff-log-nav-to-last-week {
                border-top: none;
                border-bottom: solid 1px silver;

                @media (--layout-lg) {
                    border: none;
                }
            }

            &.active {
                @media (--layout-lg) {
                    border-top: 1px solid silver;
                    border-left: 1px solid silver;
                    border-right: 1px solid silver;
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                    background-color: white;
                    color: black;
                }
            }

            &:not(.current-day).diff-log-nav-control-disabled {
                @media (--layout-lg) {
                    display: inline-block;
                }
            }
        }

        &:hover {
            & li:not(.current-day) {
                display: inline-block;
            }

            li.diff-log-nav-control-disabled {
                display: none;
            }
        }
    }
}

/* diff-log 一覧の list item */
.diff-log-info-menu-bar > a:not(:first-child),
.diff-log-info-menu-bar > span:not(:first-child) {
    margin-left: 1em;
}
.diff-log-info-code-container {
    margin: 10px;

    & > code {
        white-space: pre-wrap;
        word-break: break-all;

        & + div {
            margin-top: 10px;
        }
    }
}

/* itemごとのヘッダー*/
.diff-log-info-header {
    border-left: 8px solid;
    border-color: #337ab7;
    padding: 0.2rem 0 0.2rem 0.5rem;
    margin-bottom: 4px;

    & .diff-log-info-page-title {
        font-size: 14px;

        & .diff-log-info-filter-button {
            margin-left: 4px;
            margin-right: 8px;
            border-width: 0;
            font-size: 12px;
        }
    }

    @media (--content-sm) {
        display: flex;
        justify-content: space-between;
    }
}

/* 操作ボタン */
.diff-log-info-header-buttons {
    display: inline;
    margin-left: 8px;
    padding: .2rem 0;

    @media (--content-sm) {
        position: relative;
    }

    & .diff-log-info-operation-dropdown {
        border: none;
        background: none;
        padding: 2px 8px;
        white-space: nowrap;
        margin: -8px 0;

        @media (--content-sm) {
            margin-right: 2rem;
            margin-left: 1rem;
        }
    }

    & ul {
        display: inline;
        padding: 0;

        @media (--content-sm) {
            display: none;
            position: absolute;
            z-index: 999;
            margin: 0;
            right: 0;
            top: -6px;
            padding: 30px;
        }

        & li {
            display: inline;
            white-space: nowrap;
            margin-right: .5rem;

            @media (--content-sm) {
                background: #FFFFFF;
                padding: 8px 10px;
                margin-bottom: -8px;
                border-left: 1px solid silver;
                border-right: 1px solid silver;
                display: block;
            }

            &:first-child {
                @media (--content-sm) {
                    border-top: 1px solid silver;
                }
            }

            &:last-child {
                @media (--content-sm) {
                    border-bottom: 1px solid silver;
                }
            }
        }
    }

    &:hover ul {
        @media (--content-sm) {
            display: block;
        }
    }
}

/* 通報 */
div.diff-log-info div.diff-log-report-section {
    margin: 24px 0;
}

/* ページネーション */
ul.diff-log-pagination {
    list-style: none;
    padding-left: 4px;

    & li {
        position: relative;
        float: left;
        padding: 0;
        margin-left: -1px;
        color: #337ab7;
        text-decoration: none;
        background-color: #fff;
        border: 1px solid #ddd;

        &.diff-log-pagination-active {
            color: #fff;
            cursor: default;
            background-color: #337ab7;
            border-color: #337ab7;
            padding: 6px 12px;
        }

        &.diff-log-pagination-arrow-disabled {
            color: #777;
            cursor: not-allowed;
            padding: 6px 12px;
        }

        & a {
            display: block;
            padding: 6px 12px;
            color: #337ab7;
        }
    }

    & a {
        border-width: 0;
    }
}

/* util */
@media (--layout-lg) {
    .diff-log-hide-md {
        display: none!important;
    }
}
@media (--layout-sm) {
    .diff-log-show-md {
        display: none!important;
    }
}
@media (--content-lg) {
    .diff-log-hide-sm {
        display: none!important;
    }
}
@media (--content-sm) {
    .diff-log-show-sm {
        display: none!important;
    }
}
