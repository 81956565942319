/* calendar*.inc.php */
.style_calendar {
    padding: 0;
    border: 0;
    margin: 3px;
    color: inherit;
    text-align: center;
}

.style_td_caltop {
    padding: 5px 3px;
    margin: 1px;
    color: inherit;
    /* font-size: 80%; */
    text-align: center;
}

.style_td_today {
    padding: 5px 1px;
    margin: 1px;
    color: inherit;
    /* background-color: #FFFFDD; */
    text-align: center;
}

.style_td_sat {
    padding: 5px 1px;
    margin: 1px;
    color: inherit;
    text-align: center;
}

.style_td_sun {
    padding: 5px 0;
    margin: 1px;
    color: inherit;
    text-align: center;
}

.style_td_blank {
    padding: 5px 0;
    margin: 1px;
    color: inherit;
    text-align: center;
}

.style_td_day {
    padding: 5px 0;
    margin: 1px;
    color: inherit;
    text-align: center;
}

.style_td_week {
    padding: 5px 5px;
    margin: 1px;
    color: inherit;
    /* font-size: 80%; */
    font-weight: bold;
    text-align: center;
}
