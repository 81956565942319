.plugin-message-root {
    margin: 8px 0;

    & .show-modal-button {
        background-color: inherit;
        border: none;
        padding: 0;
        font-size: 0.8em;

        &.extended {
            border-radius: .25em;
            font-weight: bold;
            border: solid 1px;
            padding: 4px 8px;
        }
    }

    & .message-header {
        position: relative;
        padding: 8px;
        font-weight: bold;
    }

    & .message-body {
        background-color: white;
        padding: 8px;
    }

    & .message-usage {
        & .message-usage-label {
            margin: 0 1em;
        }
        & pre {
            margin: .7em 1em;
            background-color: #f0f8ff;
            color: black;
            border-top: #889 1px solid;
            border-bottom: #dde 1px solid;
            border-left: #889 1px solid;
            border-right: #dde 1px solid;
        }
    }

    & .error {
        & .show-modal-button {
            color: #f65d5d;

            &.extended {
                border-color: #f5c2c7;
                background-color: #f8d7da;
                color: #842029;
            }
        }

        & .message-header {
            background-color: #f8d7da;
            border: solid 1px #f5c2c7;
            color: #842029;
        }
    }

    & .warning {
        & .show-modal-button {
            color: #f0ad4e;

            &.extended {
                border-color: #f5e79e;
                background-color: #fcf8e3;
                color: #8a6d3b;
            }
        }

        & .message-header {
            background-color: #fcf8e3;
            border: solid 1px #f5e79e;
            color: #8a6d3b;
        }
    }

    & .info {
        & .show-modal-button {
            color: #5bc0de;

            &.extended {
                border-color: #b8daff;
                background-color: #d9edf7;
                color: #0c5460;
            }
        }

        & .message-header {
            background-color: #d9edf7;
            border: solid 1px #b8daff;
            color: #0c5460;
        }
    }
}
