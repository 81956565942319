/* fold.inc.php */
.fold-container,
.lazy-fold-container {
    margin: 8px 0;

    & .fold-toggle-button {
        float: left;
        display: block;
        cursor: pointer;
        margin: -1px 0;
        border: none;
        padding: 0;
        background-color: initial;
        color: inherit;
        font-size: 22px;
        line-height: 1em;
    }
    & .fold-summary,
    & .fold-content {
        margin-left: 28px;
    }
    & .fold-summary *:first-child,
    & .fold-content *:first-child {
        /* h1 や p による上空きをキャンセルして、summary のテキストに位置揃え */
        margin-top: 0;
    }

    /* open/close */
    &.fold-open > .hidden-on-open {
        display: none;
    }
    &:not(.fold-open) > .visible-on-open {
        display: none;
    }
}
