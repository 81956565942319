.flex-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-left: -10px;
    margin-right: -8px;
    padding-left: 10px;
    overflow-x: auto;
}
.flex-box {
    flex-basis: auto;
    margin-bottom: 10px;
    margin-left: -10px;
    padding-right: 10px;
}
.flex-box-inner {
    margin-left: 10px;
}
