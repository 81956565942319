/* nobr プラグインの本来の機能 */
.nobr, .nobr th, .nobr td {
    white-space: nowrap;
}
.nobr {
    overflow-x: auto;
}

/* 画像やテキストエリアの幅に関する仕様にも影響 */
/* 通常は枠内にぎゅっとおさめる */
img {
    max-width: 100%;
    height: auto;
}
input[type="text"],
textarea {
    max-width: 100%;
}

/* nobr の中では横スクロールさせてでも本来のサイズを維持 */
.nobr img,
.nobr input[type="text"],
.nobr textarea {
    max-width: initial;
}
