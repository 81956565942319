/* minicalendar.inc.php */
.ministyle_calendar {
    width: 150px;
    padding: 0;
    margin: 2px;
    color: inherit;
    background-color: #E0E0E0;
    text-align: center;
}
.ministyle_calendar td {
    white-space: nowrap;
}

.ministyle_td_caltop {
    padding: 2px;
    margin: 1px;
    color: inherit;
    background-color: #FFF5EE;
    font-size: 12px;
    text-align: center;
}

.ministyle_td_today {
    padding: 1px;
    margin: 1px;
    color: inherit;
    background-color: #FFFFDD;
    font-size: 10px;
    text-align: center;
}

.ministyle_td_sat {
    padding: 1px;
    margin: 1px;
    color: inherit;
    background-color: #DDE5FF;
    font-size: 10px;
    text-align: center;
}

.ministyle_td_sun {
    height: 20px;
    padding: 1px;
    margin: 1px;
    color: inherit;
    background-color: #FFEEEE;
    font-size: 10px;
    text-align: center;
}

.ministyle_td_blank {
    padding: 1px;
    margin: 1px;
    color: inherit;
    background-color: #EEEEEE;
    font-size: 10px;
    text-align: center;
}

.ministyle_td_day {
    padding: 1px;
    margin: 1px;
    color: inherit;
    background-color: #EEF5FF;
    font-size: 10px;
    text-align: center;
    vertical-align: center;
}

.ministyle_td_week {
    width: 23px;
    padding: 2px 0;
    margin: 2px 0;
    color: inherit;
    background-color: #E0E0E0;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
}

/* minicalendar_viewer */
h3.minicalendar {
    margin: 8px -8px 0 -6px;
    font-size: 100%;
    border: 0;
    border-left: 8px solid;
    border-bottom: 1px solid;
    border-color: #ffffff #ffffff #999999 #ff9933;
    color: inherit;
}

h4.minicalendar {
    margin: 8px -8px 0 -6px;
    font-size: 100%;
    border: 0;
    border-left: 8px solid;
    border-bottom: 1px solid;
    border-color: #ffffff #ffffff #999999 #ffCC66;
    color: inherit;
}

.minicalendar_viewer {
    margin: 0 0 0 0;
}

.minicalendar_viewer h4 {
    margin: 0.5em 0 0 0.5em;
}

.minicalendar_viewer p {
    margin: 0.5em 0 0 1.5em;
}

/* minicalendar+public_holiday view */
.date_weekday, .date_weekend, .date_holiday {
    /* カレンダーの数字にはゴシックフォントを使う。英数のみでよい */
    font-family: Verdana, sans-serif;
}

.date_weekday {
    font-size: 100%;
    line-height: 110%;
    font-weight: normal;
    padding: 3px 0;
    border: none;
    border-bottom: 1px solid #666;
    margin-bottom: 10px;
}

.date_weekend {
    color: #14F;
    font-size: 100%;
    line-height: 110%;
    font-weight: normal;
    padding: 3px 0;
    border: none;
    border-bottom: 1px solid #666;
    margin-bottom: 10px;
}

.date_holiday {
    color: #f41;
    font-size: 100%;
    line-height: 110%;
    font-weight: normal;
    padding: 3px 0;
    border: none;
    border-bottom: 1px solid #666;
    margin-bottom: 10px;
}

.day {
    float: left;
    font-size: 200%;
    line-height: 100%;
    font-weight: normal;
    letter-spacing: 0.02em;
    padding-left: 4pt;
    padding-right: 3pt;
    padding-top: 3pt;
    padding-bottom: 3pt;
    margin: 0;
    margin-top: 3pt;
    margin-right: 4pt;
}

.date_weekday .day {
    background-color: #333;
    color: #fff;
}

.date_weekend .day {
    background-color: #14F;
    color: #fff;
}

.date_holiday .day {
    background-color: #F41;
    color: #fff;
}
