div.diff-log-info .diff-log-report-button {
    margin-left: 8px;
    color: #215dc6;
    padding: 8px;
    border-radius: 4px;
    border-width: 0;
}
div.diff-log-info .diff-log-report-button:hover {
    background-color: #eee;
    text-decoration: none;
    color: #888;
}
div.diff-log-info .diff-log-report-button {
    color: #fff;
    background-color: #d9534f;
    text-decoration: none;
}
div.diff-log-info .diff-log-report-button[disabled],
div.diff-log-info .diff-log-report-button[disabled]:hover {
    background-color: #eee;
    color: #888;
}

#diff-log-report-modal a.close-modal {
    border: none;
    background-color: initial !important
}
#diff-log-report-modal .form-group {
    margin: 10px 0;
}
#diff-log-report-modal select,
#diff-log-report-modal textarea {
    box-sizing: border-box;
    width: 100%;
}
#diff-log-report-modal label {
    display: block;
    font-weight: bold;
}
#diff-log-report-modal h3.modal-title {
    background-color: #4f5a60;
    background-image: none;
    border-radius: 8px 8px 0 0 ;
    border-style: none;
    color: #fff;
    margin: 0;
    font-size: 1rem;
    font-weight: normal;
    padding: 0.5rem 0.8rem;
    text-decoration: none;
}
#diff-log-report-modal form {
    padding: 0.2rem 0.5rem;
}
#diff-log-report-modal .error-message {
    color: red;
    border: 1px solid red;
    border-radius: 5px;
    background-color: pink;
    margin: 10px 0;
    padding: 10px;
}

#diff-log-report-complete-modal {
}
#diff-log-report-complete-modal .modal-title {
    font-size: 20px;
    border: none;
    margin: 0;
    padding: 1rem 1rem 0;
    background: none;
}
#diff-log-report-complete-modal .modal-body {
    margin: 2em 1em;
    padding: 0 1rem;

}
#diff-log-report-complete-modal .footer {
    margin: 0;
    padding: 0 1rem 1rem;
    text-align: center;
}
#diff-log-report-complete-modal .footer .close-modal-button {
    display: inline-block;
    border: 1px solid #888;
    border-radius: 4px;
    background: #eee;
    padding: 0.5em 2em;
    cursor: pointer;
}
#diff-log-report-complete-modal .footer .close-modal-button:hover {
    background: #fff;
}
#diff-log-report-complete-modal a.close-modal,
#diff-log-report-complete-modal a.close-modal:visited {
    background-color: initial;
    text-decoration: none;
    border: none;
}
