.popout {
    & .header-container {
        flex: 0 1 auto;
        margin: 0;
    }
    & .default-header {
        margin-bottom: 8px;
        text-align: left;

        & a .title2 {
            padding-left: 8px;
        }

        & .popout-header-logo ~ a .title2 {
            padding-left: 0;
        }
    }

    &.container-wrapper {
        border: none;
        min-height: 100vh;
        display: flex;
        flex-flow: column;
    }

    & #contents {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    & #footer {
        flex: 0 1 auto;
    }

    & .container {
        margin: 0;
    }
}

.popout-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 12px 0;

    & .popout-button {
        border: 1px solid #aaa;
        border-radius: 0.25em;
        padding: 0.5em 1em;
        background-color: #fff;
        color: inherit;
        display: inline-block;
        margin-bottom: 8px;
        text-decoration: none;

        & .popout-label {
            margin-left: 8px;
        }

        &:hover {
            text-decoration: none;

            & * {
                opacity: 0.5;
            }
        }

        &:hover + .popout-content .popout-content-cover {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.1);
        }

        &.below {
            order: 1;
            margin-bottom: 0;
            margin-top: 8px;
        }
    }

    & .popout-content {
        position: relative;
        align-self: stretch;
    }
}
