/* accordion.inc.php */
.accordion-container,
.lazy-accordion-container {
    margin-bottom: 10px;

    & .accordion-header {
        cursor: pointer;
    }
    & .accordion-content {
        padding: 10px 0 0 10px;
        margin: 0;
    }

    // icon
    &>.accordion-header .fas {
        line-height: 1.6em;
        margin: 0 3px;
        padding: 2px 0;
    }
    &>.accordion-header:hover > .fas {
        opacity: 0.5;
    }

    // open
    &.accordion-open {
        &>.accordion-header .hidden-on-open {
            display: none;
        }
    }

    // close
    &:not(.accordion-open) {
        &>.accordion-header .visible-on-open,
        &>.accordion-content {
            display: none;
        }
    }
}
