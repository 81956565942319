/* vote.inc.php */
table.vote {
    border-spacing: 0;
    padding: 2px;
    border: 0;
    margin: auto auto auto 32px !important;
    text-align: left;
    color: inherit;
}

table.vote td, #header table.vote td, #footer table.vote td,
table.vote2 td, #header table.vote2 td, #footer table.vote2 td,
table.tvote td, #header table.tvote td, #footer table.tvote td {
    border: none;
    padding: 2px;
}

td.vote_td1, td.vote_td2, #footer td.vote_td2  {
    border: none;
}
