table.bugtrack_form th, table.bugtrack_form td {
    background: transparent;
    border: none;
    margin: 0;
    padding: 2px;
}

table.bugtrack_form th {
    text-align: left;
}

table.bugtrack_list {
    width: 100%
}

table.bugtrack_list th, table.bugtrack_list td {
    border-color: #8f8f8f;
    padding: 1px;
    text-align: left;
}
