
abbr,
:--user-content span.tooltip {
    border-style: none none dotted none;
    border-width: medium medium 1px medium;
    cursor: help;
    // 途中で折り返すと tippy の表示が要素から離れてしまう
    word-break: keep-all;
}

// インライン要素の中に tippy が入ってきてしまうと意図しない改行が発生するので、その対策
:--user-content a div[data-tippy-root],
:--user-content a div[data-tippy-root] div,
:--user-content span div[data-tippy-root],
:--user-content span div[data-tippy-root] div,
:--user-content strong div[data-tippy-root],
:--user-content strong div[data-tippy-root] div,
:--user-content em div[data-tippy-root],
:--user-content em div[data-tippy-root] div,
:--user-content ins div[data-tippy-root],
:--user-content ins div[data-tippy-root] div {
    display: inline-block;
}

:--user-content .tippy-box {
    font-size: 90%;
    font-weight: normal;

    // nobr で white-space; nowrap されているかもしれない
    white-space: normal;

    & a {
        // light テーマのはず
        color: #888888;
        border: none;
    }
    & a:hover {
        color: #333333;
        border: none;
        text-decoration: underline;
    }
}
