.user-icon {
    display: inline-block;
    line-height: 100%;
    vertical-align: baseline;
    position: relative;
    overflow: hidden;

    & img {
        display: block;
        position: absolute;
    }
}

a:hover .user-icon {
    filter: brightness(110%) saturate(80%);
}
