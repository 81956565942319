.plugin-ntbr:not(.unveiled) {
    background-color: #eee;
    color: #999;
    position: relative;
    cursor: pointer;
    border-radius: .25em;

    & * {
        visibility: hidden;
    }
    & td, & th {
        // Chrome 固有のバグで visibility: hidden でも collapse なテーブルの罫線が見えてしまうのの対策
        border-color: transparent!important;
    }
}

div.plugin-ntbr:not(.unveiled),
td.plugin-ntbr:not(.unveiled),
th.plugin-ntbr:not(.unveiled) {
    &::before {
        position: absolute;
        overflow: hidden;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        padding: 0 .25em;
        content: attr(data-alt);
        text-align: center;
        white-space: nowrap;
        font-size: 0.9em;
    }
    &::after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0.7rem 0.7rem 0;
        border-color: #fff rgba(0,0,0,0.5) #fff #fff;
        bottom: 0;
        left: 0;
    }
}

//.plugin-ntbr.unveiled {
//    background-color: inherit !important;
//    color: inherit !important;
//}
