span.tategaki {
    writing-mode: vertical-rl;
    text-orientation: mixed;
}

div.tategaki {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    overflow: auto;

    & table {
        writing-mode: horizontal-tb;
        text-orientation: mixed;
    }

    & h1, h2, h3, h4, h5, h6 {
        border: none;
        background: none;
    }
}

body.apple-webkit {
    // ===== Safari バグ (3年放置されている) 対策 =====
    // 縦書き領域の幅と高さをレイアウト計算に含み損ねているため、例えば伸縮可能なテーブルセルのサイズ
    // 見積もりを過剰に小さくしてしまったりする。そのため、狭い領域に文字が押し込まれてコンテンツがはみ出す。
    // https://qiita.com/moenar014/items/d8dfefcaac0e2dc989d9
    // 今のところ、-webkit-- プレフィクスのどれを当たっても問題回避作は提供されていない。
    & table span.tategaki {
        white-space: nowrap;
        // ↑ 高さが足りないセルに詰め込まれると、1文字で改行させられる。レイアウトの最終調整に文字数分の高さをねじ込む。
        min-width: 1.6em;
        // ↑ 標準的な1文字分の幅 (2行になるとこれじゃ無理で、セルサイズ指定が必要。多くの場合は省略できるということで)
    }
}






