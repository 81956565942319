.plugin-moved {
    display: flex;
    align-items: center;
    border: 2px solid #888888;
    border-radius: 6px;
    margin: 20px 0;
    padding: 10px 20px;
    font-size: 14px;
    background: #ffffff;
    color: #333333;
}

.plugin-moved-icon {
    display: block;
    font-size: 60px;
    margin: 10px;
}

.plugin-moved-text {
    padding: 6px 0 6px 0.5em;
}
.plugin-moved-title {
    font-size: 20px;
    font-weight: bold;
    margin-left: -0.5em;
    margin-bottom: 4px;
}
.plugin-moved-destination {
}
.plugin-moved-message {
    font-size: 12px;
}

/* #content に付けるページ全体が薄くなるスタイル */
.plugin-moved-content-disappeared > *:not(.plugin-moved) {
    opacity: 0.35;
}
