.notification-bar-container {
    background: #333333;
}
.notification-bar-container .notification-bar-message-box {
    padding: 4px 1em 4px 2em;
    border-top: 1px solid #888888;
    border-bottom: 1px solid #888888;
    margin-bottom: -1px;
}
.notification-bar-container .notification-bar-message-box:first-child {
    margin-top: -1px;
}
@media (--layout-sm) {
    .notification-bar-container .notification-bar-message-box {
        padding-left: 0.5em;
        padding-right: 1em;
    }
}
.notification-bar-container .notification-bar-message-close {
    float: right;
    border: none;
    background: transparent;
    color: #888888;
    padding: 0 8px;
    font-weight: bold;
    font-size: 16px;
}
.notification-bar-container .notification-bar-message-close:hover {
    color: #ffffff;
    cursor: pointer;
}
.notification-bar-container .notification-bar-message-text {
    color: #ffffff;
    font-size: 12px;
}
.notification-bar-container .notification-bar-message-text a {
    color: #FF9999;
    border: none;
    text-decoration: none;
}
.notification-bar-container .notification-bar-message-text a:hover {
    border: none;
    text-decoration: underline;
}
