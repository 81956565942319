.noscript-alert {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 9999;
    background-color: #666;
    color: white;
    padding: 6px;
    font-size: 12px;

    & i {
        color: #ffCC66;
    }
}
