.contentsx {
}

.contentsx-unavailable {
    border-radius: 6px;
    border: 1px solid #cccccc;
    background: #f8f8f8;
    color: #999999;
    font-size: 10px;
    font-weight: bold;
    padding: 8px 12px;
    margin: 20px 0;
}
