html * {
    box-sizing: border-box;
}

a:hover img {
    filter: brightness(110%) saturate(80%);;
}

.rtcomment button.post,
button,
input[type="button"],
input[type="submit"] {
    font-family: var(--system-font-family);
    border: 1px solid #aaa;
    border-radius: .25em;
    padding: .375em 1em;
    font-weight: normal;
    // iOS 15 は色が特殊
    color: #000;
    background-color: #efefef;
    line-height: 1.2em;
    cursor: pointer;
}
button:hover:not(button[disabled]),
input[type="button"]:hover:not(button[disabled]),
input[type="submit"]:hover:not(button[disabled]) {
    background-color: #dcdcdc;
}
button[disabled],
input[type="button"][disabled],
input[type="submit"][disabled] {
    border-color: #ddd;
    color: #aaa;
    cursor: not-allowed;
}

input[type="text"],
input[type="password"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
select,
textarea {
    font-family: var(--system-font-family);
    border: 1px solid #ccc;
    border-radius: .25em;
    padding: .375em;
}

input[type="search"] {
    font-family: var(--system-font-family);
    -webkit-appearance: none;
    outline-offset: 0;
    background-color: #fff;
}

select {
    // iOS 15 は色が特殊
    color: #000;
    background: #fff;

    // iOS と Mac では padding が効かないので高さを固定で...
    height: 2.26em;

    // ちなみに Mac Safari では背景色指定しても OS ネイティブのボタンを使いよる。
    // かといって -webkit-appearance をいじると select の右端のマークが消える。
}
// input[type=file] なんかは中のボタンを CSS セレクタで指定できないから、
// UI を非表示にして完全に JS で作り直す以外どうしようもない。

select[size] {
    height: auto;
}

textarea {
    font-size: 12px;  // なんで input はそのままで textarea だけ 12px なんだろう...
    line-height: 1.6em;
    height: 8.9em;
    max-width: 100%;
}

input[type="file"] {
    font-family: var(--system-font-family);
}

input,
textarea,
.pukiwiki-show-fontset-img {
    margin-top: 2px;
    margin-bottom: 2px;
}

.edit_form textarea[name="msg"],
.edit_form textarea[name="areaedit_msg"],
.memo_form textarea[name="msg"],
.memo_conflict textarea[name="msg"],
.memox_form textarea[name="msg"],
.memox_conflict textarea[name="msg"],
.insert_form textarea[name="msg"],
.insert_conflict textarea[name="msg"],
.article_form textarea[name="msg"],
.article_conflict textarea[name="msg"],
.bugtrack_form textarea[name="body"] {
    width: 100%;
}
.article_form textarea[cols="70"] {
    max-width: 70em;
}
.bugtrack_form textarea[cols="60"] {
    max-width: 60em;
}
table.bugtrack_form {
    width: 100%;
}
.tableedit_form .tableedit_div {
    padding-left: 2em;
}
.tableedit_form textarea.tableedit_cell {
    width: 100%;
}
.tableedit_form textarea.tableedit_cell[cols="80"] {
    max-width: 80em;
}

table.normalize >tbody > tr > th, table.normalize >tbody > tr > td{
    background: transparent;
    border: transparent;
    margin: 0;
    padding: 0;
}


// スキンスタイルをリセット
body .system-ui {
    font-family: var(--system-font-family);
}

body .system-ui-modal,
body .system-ui-panel {
    font-family: var(--system-font-family);
    font-size: var(--system-font-size);
    color: var(--system-text-color);
    background-color: var(--system-background-color);
}
