.heading-with-anchor-link {
    position: relative;
}
.heading-with-anchor-link .anchor-link {
    position: absolute;
    right: 8px;
    opacity: 0.2;
}
.heading-with-anchor-link:hover .anchor-link {
    opacity: 1;
}
