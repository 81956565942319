.showrss2 {
    margin-left: 2em;
}

.showrss2-timestamp {
    font-size: 90%;
    font-weight:bold
}

#menubar .showrss2 {
    margin-left: 0;
}
