:root {
    --responsive-layout-item-gap: 32px;
}

.responsive-layout-container {
    overflow-x: hidden;
    margin: 20px 0;

    & > .responsive-layout-row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        // マイナスマージンとパディングでないとネストしたとき幅の比が期待通りに計算されない
        margin: 0 calc(-1 * var(--responsive-layout-item-gap) / 2);
        width: calc(100% + var(--responsive-layout-item-gap));
        overflow-x: auto;

        & > .responsive-layout-item {
            padding: 0 calc(var(--responsive-layout-item-gap) / 2);

            & > .responsive-layout-item-content {
                overflow-x: auto;
            }
        }

        & > .responsive-layout-container {
            margin: 0;
            & > .responsive-layout-row {
                margin: 0;
                width: 100%;
            }
        }

        & > * { flex: auto; width: auto; }

        & > .responsive-layout-w-fixed { flex: none; }

        // 基本の幅比率
        & > .responsive-layout-w-auto { width: auto; }
        & > .responsive-layout-w-1 {width: calc(100% * 1 / 12);}
        & > .responsive-layout-w-2 {width: calc(100% * 2 / 12);}
        & > .responsive-layout-w-3 {width: calc(100% * 3 / 12);}
        & > .responsive-layout-w-4 {width: calc(100% * 4 / 12);}
        & > .responsive-layout-w-5 {width: calc(100% * 5 / 12);}
        & > .responsive-layout-w-6 {width: calc(100% * 6 / 12);}
        & > .responsive-layout-w-7 {width: calc(100% * 7 / 12);}
        & > .responsive-layout-w-8 {width: calc(100% * 8 / 12);}
        & > .responsive-layout-w-9 {width: calc(100% * 9 / 12);}
        & > .responsive-layout-w-10 {width: calc(100% * 10 / 12);}
        & > .responsive-layout-w-11 {width: calc(100% * 11 / 12);}
        & > .responsive-layout-w-12 {width: 100%;}

        // タブレットサイズ以下のときのみ有効な幅比率
        @media (--layout-sm) {
            & > .responsive-layout-wm-auto { width: auto; }
            & > .responsive-layout-wm-1 {width: calc(100% * 1 / 12);}
            & > .responsive-layout-wm-2 {width: calc(100% * 2 / 12);}
            & > .responsive-layout-wm-3 {width: calc(100% * 3 / 12);}
            & > .responsive-layout-wm-4 {width: calc(100% * 4 / 12);}
            & > .responsive-layout-wm-5 {width: calc(100% * 5 / 12);}
            & > .responsive-layout-wm-6 {width: calc(100% * 6 / 12);}
            & > .responsive-layout-wm-7 {width: calc(100% * 7 / 12);}
            & > .responsive-layout-wm-8 {width: calc(100% * 8 / 12);}
            & > .responsive-layout-wm-9 {width: calc(100% * 9 / 12);}
            & > .responsive-layout-wm-10 {width: calc(100% * 10 / 12);}
            & > .responsive-layout-wm-11 {width: calc(100% * 11 / 12);}
            & > .responsive-layout-wm-12 {width: 100%;}
        }

        // モバイルサイズ以下のときのみ有効な幅比率
        @media (--content-sm) {
            & > .responsive-layout-ws-auto { width: auto; }
            & > .responsive-layout-ws-1 {width: calc(100% * 1 / 12);}
            & > .responsive-layout-ws-2 {width: calc(100% * 2 / 12);}
            & > .responsive-layout-ws-3 {width: calc(100% * 3 / 12);}
            & > .responsive-layout-ws-4 {width: calc(100% * 4 / 12);}
            & > .responsive-layout-ws-5 {width: calc(100% * 5 / 12);}
            & > .responsive-layout-ws-6 {width: calc(100% * 6 / 12);}
            & > .responsive-layout-ws-7 {width: calc(100% * 7 / 12);}
            & > .responsive-layout-ws-8 {width: calc(100% * 8 / 12);}
            & > .responsive-layout-ws-9 {width: calc(100% * 9 / 12);}
            & > .responsive-layout-ws-10 {width: calc(100% * 10 / 12);}
            & > .responsive-layout-ws-11 {width: calc(100% * 11 / 12);}
            & > .responsive-layout-ws-12 {width: 100%;}
        }
    }

    // デスクトップサイズでもブレーク縦レイアウトするスタイル
    &.responsive-layout-break-l > .responsive-layout-row {
        flex-direction: column;
    }

    // タブレットデバイスへダウングレードしたら縦向きに
    @media (--layout-sm) {
        &.responsive-layout-break-m > .responsive-layout-row {
            flex-direction: column;

            & > .responsive-layout-w-fixed > .responsive-layout-item-content {
                width: auto!important;
            }
            & > .responsive-layout-w-auto { width: auto;}
            & > .responsive-layout-w-1 { width: auto;}
            & > .responsive-layout-w-2 { width: auto;}
            & > .responsive-layout-w-3 { width: auto;}
            & > .responsive-layout-w-4 { width: auto;}
            & > .responsive-layout-w-5 { width: auto;}
            & > .responsive-layout-w-6 { width: auto;}
            & > .responsive-layout-w-7 { width: auto;}
            & > .responsive-layout-w-8 { width: auto;}
            & > .responsive-layout-w-9 { width: auto;}
            & > .responsive-layout-w-10 { width: auto;}
            & > .responsive-layout-w-11 { width: auto;}
            & > .responsive-layout-w-12 { width: auto;}
        }
    }

    // さらにモバイルデバイスにダウングレードしたら縦向きに
    @media (--content-sm) {
        &.responsive-layout-break-s > .responsive-layout-row  {
            flex-direction: column;

            & > .responsive-layout-w-fixed > .responsive-layout-item-content {
                width: auto!important;
            }
            & > .responsive-layout-w-auto { width: auto;}
            & > .responsive-layout-w-1 { width: auto;}
            & > .responsive-layout-w-2 { width: auto;}
            & > .responsive-layout-w-3 { width: auto;}
            & > .responsive-layout-w-4 { width: auto;}
            & > .responsive-layout-w-5 { width: auto;}
            & > .responsive-layout-w-6 { width: auto;}
            & > .responsive-layout-w-7 { width: auto;}
            & > .responsive-layout-w-8 { width: auto;}
            & > .responsive-layout-w-9 { width: auto;}
            & > .responsive-layout-w-10 { width: auto;}
            & > .responsive-layout-w-11 { width: auto;}
            & > .responsive-layout-w-12 { width: auto;}
            & > .responsive-layout-wm-auto { width: auto;}
            & > .responsive-layout-wm-1 { width: auto;}
            & > .responsive-layout-wm-2 { width: auto;}
            & > .responsive-layout-wm-3 { width: auto;}
            & > .responsive-layout-wm-4 { width: auto;}
            & > .responsive-layout-wm-5 { width: auto;}
            & > .responsive-layout-wm-6 { width: auto;}
            & > .responsive-layout-wm-7 { width: auto;}
            & > .responsive-layout-wm-8 { width: auto;}
            & > .responsive-layout-wm-9 { width: auto;}
            & > .responsive-layout-wm-10 { width: auto;}
            & > .responsive-layout-wm-11 { width: auto;}
            & > .responsive-layout-wm-12 { width: auto;}
        }
    }
}
